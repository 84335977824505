import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import "./styles.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Baloo+Bhai&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main>{children}</main>
      <footer className="centered-content">
        <p style={{ margin: 0 }}>
          <small>&copy; {new Date().getFullYear()} Ubiquitools LLC</small>
        </p>
        <p>
          <small>
            <Link to="/legal/terms-of-use">Terms of Use</Link>
          </small>
          <small> | </small>
          <small>
            <Link to="/legal/privacy-policy">Privacy Policy</Link>
          </small>
        </p>
      </footer>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
